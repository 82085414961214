/**
* 左边菜单
*/
<template>
  <div @mouseenter.stop="menuOpen" @mouseleave.stop="menuCollapse">
    <el-menu
      :collapse="collapsed"
      collapse-transition
      router
      :default-active="defaultActive"
      unique-opened
      class="el-menu-vertical-demo"
      :background-color="$store.state.customColors"
      text-color="#2E2E2E"
      active-text-color="#0089FF"
    >
      <div class="logobox" :style="'border-color:' + $store.state.customColors">
        <router-link class="logo" :to="{path:'/'}">
          <img class="logoimg" :src="$localPicture+'logo/logo_small.png'" alt>
          <p v-show="!collapsed">
            仓储系统
          </p>
        </router-link>
        <el-tooltip class="item" effect="dark" :content="isCollapsing ? '展开' : '收起'" placement="right">
          <i
            :class="isCollapsing ? 'icon menu_stow' : 'icon menu-open'"
            @click="changeCollapsed"
          />
        </el-tooltip>
      </div>
      <div v-for="menu in allmenu" :key="menu.resCode" :class="collapsed ? 'menu-hide': 'menu-display'">
        <el-submenu v-if="menu.resOperType === '1'" :index="menu.resCode">
          <template slot="title">
            <i :class="menu.icon||'el-icon-s-order'" />
            <span v-show="!collapsed">{{ menu.resName }}</span>
          </template>
          <el-menu-item-group>
            <div v-for="chmenu in menu.children" :key="chmenu.resCode">
              <el-menu-item v-if="chmenu.resOperType === '2'" :index="chmenu.resUrl">
                <div>
                  <!-- <i :class="chmenu.icon||'el-icon-s-order'" /> -->
                  <span>{{ chmenu.resName }}</span>
                </div>
              </el-menu-item>
            </div>
          </el-menu-item-group>
        </el-submenu>
        <!-- 直接是没有子孩子的 -->
        <el-menu-item v-else-if="menu.resOperType === '2'" :index="menu.resUrl">
          <i :class="menu.icon||'el-icon-s-order'" />
          <span v-show="!collapsed">{{ menu.resName }}</span>
        </el-menu-item>
      </div>
    </el-menu>
  </div>
</template>
<script>
import { getAllPermission } from '@/api/institutionalFramework'
import { currLoginCmpWarehouseList } from '@/api/warehousingSystem'
import { BSERURL } from '@/config/index'
export default {
  name: 'Leftnav',
  data() {
    return {
      collapsed: false,
      allmenu: [],
      defaultActive: '',
      isCollapsing: false
    }
  },
  watch: {
    '$route': {
      handler(newVal) {
        this.defaultActive = newVal.meta.parentPath || sessionStorage.getItem('defaultActivePath')
        sessionStorage.setItem('defaultActivePath', newVal.meta.parentPath || sessionStorage.getItem('defaultActivePath'))
      },
      immediate: true,
      deep: true
    }
  },
  // 创建完毕状态(里面是操作)
  created() {
    // 这个websocket的是判断有没有相同的登录的
    const baseUrl = BSERURL.replace('https', 'wss').replace('http', 'ws')
    var ws = new WebSocket(
      `${baseUrl}/websocket/${sessionStorage.getItem(
        'tokenWms'
      )}`,
      ['superchat']
    )
    // 获取连接状态
    ws.onmessage = () => {
      this.$alert('您的账号已经在其他设备上登录', '提示', {
        confirmButtonText: '确定',
        showClose: false,
        callback: () => {
          this.$router.push({ path: '/login' })
          // 完成通信后关闭WebSocket连接
          ws.close()
        }
      })
    }
    // 获取仓库的下拉列表
    if (localStorage.getItem('warehouseList')) this.$store.commit('getWarehouseList', JSON.parse(localStorage.getItem('warehouseList')))
    currLoginCmpWarehouseList(res => {
      this.$store.commit('getWarehouseList', res.data)
    }, () => {
      this.$store.commit('getWarehouseList', [])
    })
    getAllPermission(res => {
      // 取出相同的进行渲染
      // const owningMenus = res.data.filter(item => {
      //   return arr.includes(item.resCode)
      // })
      const data = [...res.data]
      if (data.length > 0 && data[0].children) this.allmenu = [...data[0].children]
    }, () => { }, '09')
  },
  methods: {
    changeCollapsed() {
      this.isCollapsing = !this.isCollapsing
      if (this.isCollapsing) {
        this.collapsed = true
      }
      this.$emit('changeWidth', { collapsed: this.collapsed, isCollapsing: this.isCollapsing })
    },
    menuOpen() {
      if (this.isCollapsing) {
        this.collapsed = false
        this.$emit('changeWidth', { collapsed: this.collapsed, isCollapsing: this.isCollapsing, showPlaceholder: true })
      }
    },
    menuCollapse() {
      if (this.isCollapsing) {
        this.collapsed = true
        this.$emit('changeWidth', { collapsed: this.collapsed, isCollapsing: this.isCollapsing, showPlaceholder: false })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.el-menu--collapse {
  width: 85px;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  min-height: 400px;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  border: none;
  text-align: left;
}
.el-menu-item [class^=el-icon-] {
  margin-left: 12px;
}
.el-submenu [class^=el-icon-] {
  margin-left: 12px;
}
.el-menu-item-group__title {
  padding: 0px;
}
.el-menu-bg {
  background-color: #1f2d3d !important;
}
.el-menu {
  border: none;
}
.logobox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000000;
  font-size: 18px;
  text-align: center;
  padding: 0 22px;
  height: 104px;
  font-weight: bold;
  border-bottom: 1px solid #EFEFEF;
  .logo {
    display: flex;
    align-items: center;
    color: $mainFontColor;
  }
  .logoimg {
   margin-right: 18px;
   width: 56px;
   height: 56px;
  }
  .icon {
    width: 23px;
    height: 23px;
    cursor: pointer;
    display: inline-block;
    transition: 0.4s;
  }

  .menu-open {
    background: url('../assets/image/menu_open.svg') no-repeat top center;
    &:hover {
      background: url('../assets/image/menu_open_a.svg') no-repeat top center;
    }
  }
  .menu_stow {
    background: url('../assets/image/menu_open.svg') no-repeat top center;
    transform: rotateY(180deg);
    &:hover {
      background: url('../assets/image/menu_open_a.svg') no-repeat top center;
    }
  }
}

.menu-hide {
 /deep/ .el-submenu__icon-arrow {
    display: none;
  }
}

.menu-display {
  /deep/ .el-submenu__icon-arrow {
    display: block;
  }
}
.el-submenu .el-menu-item{
  padding-left: 92px!important;
}

</style>
